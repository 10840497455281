.showcaseImg {
    height:530px;
    max-width:300px;
    object-fit:cover;
    position:absolute;
    right:25%;
    z-index:2;
    bottom:0rem;
}

.showcaseSlideElement {
    position:relative;
    height:700px;
    background: linear-gradient(0deg, var(--turcoaz) 0%, var(--turcoaz) 50%, var(--mov) 100%);
}

.showcaseElemTextContainer {
    position:absolute;
    background-color: var(--alb);
    width: 50%;
    height:100%;
    margin-top:5rem;
}

.showcaseElemTitle {
    text-align: left;
    margin-left:100px;
    margin-top:3rem;
    font-family:"Lato2"
}

.showcaseElemContent {
    text-align: left;
    margin-left:100px;
    margin-top:6rem;
    font-size:2rem;
}

.showcaseContainer {
    background-color: var(--alb);
    padding-bottom:30px;
}

.showcaseDiagonal {
    background-color: var(--alb);
    width:25%;
    height:100%;
    position:absolute;
    left:50%;
    margin-top:5rem;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 0 0);
}

.showcaseDiagonalLeft {
    background-color: var(--alb);
    width:25%;
    height:100%;
    position:absolute;
    right:50%;
    margin-top:5rem;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 0 0);
    transform:scaleX(-1)
}

.showcaseImgShadow1 {
    height:530px;
    width:300px;
    object-fit:cover;
    position:absolute;
    right:calc(25% - 25px);
    z-index:2;
    bottom:25px;
    background-color: var(--alb);
    z-index: 1;
}
.showcaseImgShadow2 {
    height:530px;
    width:300px;
    object-fit:cover;
    position:absolute;
    right:calc(25% - 50px);
    z-index:2;
    bottom:50px;
    background-color: var(--turcoaz);
    z-index: 0;
}

.showcaseArrow {
    border:none;
    background-color: var(--alb);
    cursor:pointer;
    margin-top:3rem;
}
/* .showcaseFlex {
    display:flex;
} */

@media only screen and (max-width: 768px) {
    .showcaseImg {
        position:relative;
        margin:auto;
        left:0;
        right:0;
        /* margin-top:3rem; */
        top:100px;
        max-width:140px;
        height:280px;
    }
    .showcaseImgShadow1 {
        position:absolute;
        margin:auto;
        left:0;
        right:0;
        /* margin-top:3rem; */
        top:-220px;
        max-width:140px;
        height:280px;
        background-color: var(--alb);
        z-index: 1;
        transform:translateX(20px)
    }
    .showcaseImgShadow2 {
        position:absolute;
        margin:auto;
        left:0;
        right:0;
        /* margin-top:3rem; */
        top:-230px;
        max-width:140px;
        height:280px;
        background-color: var(--turcoaz);
        z-index: 0;
        transform:translateX(40px)
    }

    .showcaseElemTitle {
        position:relative;
        top:100px;
        margin-left:0px;
        padding:10px;
        text-align: center;
        background-color: var(--alb);
        margin-bottom:0px;
        font-size:1.5rem;
    }
    .showcaseElemContent {
        position:relative;
        top:100px;
        margin-left:0px;
        padding:10px;
        margin-top:0px;
        text-align: center;
        background-color: var(--alb);
        min-height:400px;
        overflow-y: scroll;
        margin-bottom:100px;
        font-size:1rem;
    }

    .showcaseMobileBackground {
        background-color: var(--alb);
        clip-path: polygon(33% 50%, 66% 0, 100% 0, 100% 100%, 0 100%, 0 50%);
        width:100%;
        height:65%;
        top:8%;
        position:absolute;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset;
    }
  }