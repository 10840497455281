.trainerCard {
    margin:0 .25rem;
    transition: all .3s ease;
}

.speakersContainer {
    background-color: #EEEBF1;
    /* position:relative; */
    top:-60px;
    padding-top:60px;
    z-index:1;
    border-radius: 0 0 50% 50%/ 0 0 2% 2%;
    padding-bottom:60px;
}

.trainerCard:hover {
    margin: 0 1.5rem;
    transform:scale(1.1);
}

.trainerCardImgContainer {
    position:relative;
}

.trainerCardImg {
    width:100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    transition: all .3s ease;
}

.trainerImgBorder {
    position:absolute;
    z-index:3;
    border-style:solid;
    border-width:4px;
    border-color:var(--mov);
    width:85%;
    height:85%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.trainerCardName {
    margin:0px;
    text-align: center;
    /* font-weight:semibold; */
}

.trainerCardPosition{
    text-align: center;
    margin-top:.25rem;
}

.trainersPageSimpleCarousel {
    display:flex;
    gap:20px;
    justify-content: center;
    flex-wrap:wrap;
}

.trainersPageTitle {
    text-align: center;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
.scrollMenuContainer {
    /* display:flex;
    justify-content: center;
    width:100vw; */
    margin:0 auto;
}

.react-horizontal-scrolling-menu--scroll-container {
    justify-content: center;
}

.react-horizontal-scrolling-menu--inner-wrapper  {
    overflow-y:visible !important;
}