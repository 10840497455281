@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-semibold.ttf');
}

.contact-wrapper {
    width: 100dvw;
    height: fit-content;
    background: url('./background.svg');
    position: relative;
    padding-top: 150px;
 
}

.bg-art {
    height: 2000px;
    position: absolute;
    right: 15dvw;
    top: -300px;
    right: 0px;   
    z-index: 0;
}

.contact-container 
{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 950px;
    width: 45dvw;
    background-color: var(--alb);
    border-radius: 0px 80px 0px 0px;
    gap: 26px; 
    z-index: 1;
}

.contact-us-text {
    font-family: 'Lato';
    font-size: 64px;
    font-weight: 500;
    color: var(--mov);
    margin-top: 200px;
    margin-bottom: 40px;
}

.contact-input {
    position: relative;
    width: 70%;
    height: 62px;
    color: var(--mov);
    font-size: 24px;
    font-weight: 400;
    font-family: 'Config';
    background: none;
    border: 3px solid var(--mov);
    border-radius: 15px;
    transition: all .3s ease-in-out;
    z-index: 100;
    padding-left:20px;
}

.contact-input::placeholder {
    color: var(--mov);
    font-size: 24px;
    font-weight: 400;
    font-family: 'Config';
    opacity: 60%;
    margin-left: 10px;
}

.contact-input:focus {
    transform: scale(1.1);
}

.long-input {
    height: 195px;
    text-align: top;
    padding-top:10px;
}

.long-input::placeholder {
    text-align: top;
}

.contact-button {
    width: 128px;
    height: 54px;
    border-color: var(--mov);
    border: 3px solid;
    background: none;
    color: var(--mov);
    font-family: 'Lato';
    font-weight: 700;
    font-size: 28px;
    border-radius: 15px;
}

.border-gradient-purple {
    border-image-source: linear-gradient(to bottom, var(--mov), rgba(63, 37, 105, .1));
    border-image-slice: 1;
    border-width: 4px;
}

.contact-button:hover {
    transform: scale(1.1);
}

.contact-main {
    width: 100dvw;
    display: flex;
    justify-content: space-between;
}

.contact-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5%;
    align-items: center;
}

.contact-person{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--alb);
    font-family: 'Config';
    margin-top: 50px;
}

.puzzle-left, .puzzle-right {
    background: url('./puzzle-left.svg') no-repeat;
    background-size: contain;
}

.contact-person-photo, .contact-person-photo-right {
    height: 281px;
    width: 286px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 35px;
    margin-bottom: 18px;
}

.puzzle-foto {
    width: 70%;
    margin-left: 40px;
    margin-top: 50px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.puzzle-right {
    background: url('./puzzle-right.svg') no-repeat;
    background-size: contain;
}

.contact-person-photo-right {
    margin-right: 0px;
    margin-left: 75px;
    margin-top: 0px;
}

.contact-person-photo-right .puzzle-foto {
    margin-left: 0px;
    margin-right: 60px;
}

.contact-person-name {
    font-family: 'Lato';
    font-size: 25px;
}

@media only screen and (max-width: 768px) {
    .contact-main {
        flex-direction: column-reverse;
    }

    .contact-person-name {
        font-family: 'Lato';
        font-size: 20px;
    }

    .contact-container {
        width: 100%;
        border-radius: 0px;
        margin-top: 90px;
        height: fit-content;
        padding-bottom: 95px;
    }

    .contact-us-text {
        font-size: 24px;
        margin-top: 50px;
        font-family: 'Lato';
        line-height: 28px;
        margin-bottom: 10px;
    }

    .contact-input {
        width: 85%;
        height: 55px;
        font-size: 17px;
        padding-left: 15px;
        margin-top: 0px;
    }

    .contact-input::placeholder {
        font-size: 17px;
        font-family: 'Lato';
    }

    .contact-input:focus {
        transform: none;
    }

    .long-input {
        height: 176px;
    }

    .contact-button {
        width: 146px;
        height: 50px;
        font-size: 20px;
        font-size: 'Lato';
        font-weight: 400;
    }

    .contact-person-photo, .contact-person-photo-right {
        height: 203px;
        width: 200px;
        margin-right: 35px;
        margin-bottom: 18px;
    }

    .puzzle-foto {
        margin-left: 35px;
        margin-top: 35px;
    }

    .contact-person-photo-right .puzzle-foto {
        margin-left: 0px;
        margin-right: 35px;
        margin-top: 35px;
    }

}
