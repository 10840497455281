.previousEditionsContainer {

    list-style-type: none;
    width:75vw;
    margin:2rem auto;
    color:var(--mov);
    padding-bottom:30px;

}

.prevEditionContainer {
    display:flex;
    gap:20px;
    margin-top:2rem;
}

.prevEditionNumeral {
    background-image:url("../../../public/puzzlePiece.png");
    background-repeat: no-repeat;
    background-size:30px 30px;
    width:30px !important;
    height:30px;
    text-align:center;
    display:flex;
    align-items: center;
    justify-content: center;
    position:relative;
    color:var(--alb);
    flex-shrink:0;
}

.prevEditionNumeral > p {
    transform:translateX(-3px);
}

.prevEditionTextContainer {

}

.prevEditionTitle {
    margin:0;
    font-size:1.75rem;
}

.prevEditionDescription {
    margin-top:.25rem;
    margin-bottom:.25rem;
    font-size:1.25rem;
}

@media only screen and (max-width: 768px) {
    .prevEditionDescription {
        position:relative;
        left:-50px;
        width:75vw;
    }
}