.navbarContainer {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  color: var(--alb);
  font-size: 1.5rem;
  transition: all 1s ease;
  height: 75px;
}

.navbarContainer > div > a {
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.navbarContainer > * {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.navbarButtons {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
}

.navbarListEl {
  margin: 0 0.5rem;
  transition: all 0.3s ease;
}

.navbarListEl > a {
  color: var(--alb);
  text-decoration: none;
}

.navbarListEl:hover {
  transform: scale(1.1);
  margin: 0 0.75rem;
}

.navbarLogo {
  width: 50%;
}

.navbarApply {
  color: var(--alb);
  background: transparent;
  border-style: solid;
  border-width: 3px;
  border-color: var(--portocaliu);
  font-size: 1.5rem;
  padding: 5px 10px;
  border-radius: 15px;
}

.mobileNavbar {
  position:fixed;
  z-index:5;
  color:var(--alb);
  transition: all .3s ease;
}

.mobileNavbarHeader {
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:10px;
  width:calc(100vw - 20px);
}

.ideaMobileNavbar {
  width:75px;
  transition: all .3s ease;
}

.buttonMobileNavbar {
  width:50px;
  height:50px;
  background-color: transparent;
  border:none;
  border-radius:50%;
  display:flex;
  justify-content: center;
  align-items: center;
  width:45px;
  height:45px;
  padding:10px;
}
.buttonMobileNavbar > img {
  width:30px;
  height:30px;
}

.mobileNavbarButtons {

}

.mobileNavbarButtons {
  list-style-type: none;
  color:var(--alb);
  transform:translateX(-250px);
  transition:all .3s ease;
  height:0px;
}

.mobileNavbarListEl > a {
  color:var(--alb) !important;
  text-decoration: none;
  font-size:1.25rem;
}

.mobileNavbarListEl {
  margin: 0.5rem 0;
}

.navbarOpen {
  background-color: rgba(63, 37, 105,0.8);
}

.navbarListOpen {
  transform:translateX(0px);
  height:fit-content;
}

@media only screen and (max-width: 768px) {
  .navbarContainer {
    display:none;
  }
}