.App {
}

body {
  overflow-x: hidden;
  background-color: var(--alb);
  color: var(--mov);
  z-index:0;
  overflow:hidden;
}

html {
  scroll-behavior: smooth;
  overflow-x:hidden;
}
.programRulesContainer {
  background: linear-gradient(
    300deg,
    var(--roz) 0%,
    var(--mov) 75%,
    var(--mov) 100%
  );
  position:relative;
  top:-60px;
  padding-top:60px;
  z-index:1;
  margin-bottom:-150px;
  border-radius: 0 0 50% 50%/ 0 0 5% 5%;
}

.teamBackgroundImg {
  position:absolute;
  left:0;top:-500px;
  z-index:0;
  opacity:50%;
  filter:blur(3px);
}

a {
  color:inherit;
  text-decoration: none;
}