.aboutContainer{
    width:100vw;
    display:flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    position:relative;
}

/* .aboutContainer > .carousel-root {
    height:100% !important;
}
.aboutContainer > .carousel-root > .carousel {
    height:100%;
} */
.aboutDescription {
    padding-bottom:30px;
    /* width:50vw; */
    height:100%;
    color:var(--alb);
}

.aboutBackground {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 36% 100%);
    width:100%;
    height:100%;
    background-color: var(--mov);
    position:absolute;
    right:0;
    top:0;
    z-index:-1;
}

.aboutIdeaImg {
    width:300px;
    height:300px;
    position:absolute;
    top:45%;
    left:55%;
    transform:translate(-50%,-50%);
}

.aboutImgContainer {
    min-width:50vw;
    height:100%;
    opacity:0%;
    transform:translateX(100vw);
    transition: all 2s ease;
}

.aboutFullBackground {
    position:absolute;
    z-index:-5;
    height:100%;
    width:100%;
    object-fit: cover;
}

.aboutCarouselContainer {
    opacity:0%;
    transform:translateX(-100vw);
    transition: all 2s ease;
    margin-left:30px;
    font-size:1.5rem;
}
.aboutCarouselAnimate {
    opacity:100%;
    transform:translateX(0px);
}


.aboutImgAnimate {
    opacity:100%;
    transform:translateX(0px);
}

@media only screen and (max-width: 768px) {
    .aboutCarouselContainer {
        margin-left:0px;
        padding: 0 10px;
        font-size:1rem;
    }

    .aboutContainer {
        height:75vh;
    }
}