body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --alb: #eef1f4;
  --mov: #3f2569;
  --roz: #f781bb;
  --turcoaz: #00a2a4;
  --portocaliu: #fe9754;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  transition: all 0.3s ease;
  cursor: pointer;
  color:var(--mov)
}

button:hover {
  transform: scale(1.1);
}

@font-face {
  font-family: Config;
  src: url("./fonts/Config-Regular.otf") format("opentype"),
    url("./fonts/Config-SemiBold.otf") format("opentype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

@font-face {
  font-family: "Lato2";
  src: url("./fonts/lato-black.ttf") format("truetype"),
    url("./fonts/lato-bold.ttf") format("truetype"),
    url("./fonts/lato-semibold.ttf") format("truetype");
}

body {
  font-family: "Config";
}
