.landingPageContainer {
  position: relative;
  background: linear-gradient(
    200deg,
    var(--roz) 0%,
    var(--mov) 80%,
    var(--mov) 100%
  );
  color: var(--alb);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.landingPageTitle {
  width: 40%;
  text-align: center;
  font-size: 3rem;
  z-index: 1;
}

.landingPageSubtitle {
  width: 40%;
  text-align: center;
  font-size: 2rem;
  z-index: 1;
}

.countdownTitle {
  font-size: 2rem;
  z-index: 1;
  position:relative;
}

.countdownSubtitle {
  font-size:1.5rem;
  z-index:1;
  margin-top:.5rem;
  margin-bottom:-0.75rem;
}

.countdownTitle::before {
  content:"";
  position:absolute;
  height:4px;
  width:70%;
  background-color: var(--turcoaz);
  bottom:-5px;
  left:-10px;
}

.landingPageButton {
  font-size: 1.5rem;
  background-color: var(--turcoaz);
  color: var(--alb);
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  z-index: 1;
  margin-top: 1.5rem;
  margin-bottom:20px;
}

.landingPageLightbulb {
  position: absolute;
  left: 20px;
  animation: pulse 10s ease;
  animation-iteration-count: infinite;
  width: 33%;
  z-index: 0;
}
.landingPageImgMass {
  position: absolute;
  animation: pulse 12s ease;
  animation-iteration-count: infinite;
  z-index: 0;
}

.countdownContainer {
  color:var(--roz);
  font-size:3rem;
}

@media only screen and (max-width: 768px) {
    .landingPageTitle {
        width:80%;
        padding-top:0rem;
        font-size:2rem;
    }

    .landingPageSubtitle {
        width:80%;
        font-size:1.5rem;
    }

    .landingPageContainer {
        height:fit-content
    }

    .countdownTitle {
        font-size:1.5rem;
    }

    .landingPageButton {
        font-size:1rem;
    }

}