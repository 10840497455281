
.rulesContainer {
    display:flex;
    width:100vw;
    justify-content: space-between;
    margin-top:100px;
    height:800px;
}

.rulesBoxContainer {
    background-color: var(--alb);
    padding:50px;
    border-radius: 60px 0px 0px 0px;
    width:75%;
    overflow-y:scroll;
}

.rulesBoxContainer > h1 {
    font-size:3rem !important;
    color:var(--mov);
}

.rulesImgContainer {
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    flex-direction: column;
}

.rulesImgContainer > img {
    width:75%;
}

.rulesJoinButton {
    font-size:2rem;
    border:none;
    background-color: var(--turcoaz);
    color:var(--alb);
    padding:10px 15px;
    border-radius:15px;
    border-style:solid;
    border-width:2px;
    border-color:var(--portocaliu)
}

.ruleHeader {
 display: flex;
 border:none;
 width:33vw;
 justify-content: space-between;
 padding:0px;

 /* height:40px; */
 /* height: fit-content; */

 opacity:100%;
 transition: all .3s ease;

 margin-bottom:20px;
 z-index:3;
 color: var(--alb);
 align-items: center;
 align-items: stretch;
 font-size:1.25rem;
}

.ruleHeader > p {
    margin-bottom:5px;
    margin-top:5px;
}

.rulesHeaderTriangle{
    min-height:100%;
    width:20px;
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    background-color: var(--alb);
    position:relative;
    right:-2px;
}

.rulesHeaderArrow {
    margin-right:10px;
}

.ruleHeader > p {
    margin-left:10px;
}

.ruleHeader + .ruleContent {
    /* display:none; */
    height:0px;
    opacity:0%;
    transition: opacity .3s ease;
    display:none;
    white-space: pre-wrap;

}

.selectedArrow {
    transform:rotate(-90deg);
}

.selectedRule + .ruleContent {
    display:block;
    height:fit-content;
    opacity:100%;
    
}

.hideRule {
    height:0px;
    opacity:0%;
    margin-bottom:0px;
}

@media only screen and (max-width: 768px) {
    .rulesContainer {
        flex-direction: column;
        margin-bottom:100px;
    }

    .ruleHeader {
        width:100%;
        margin:.5rem auto;
        z-index:10;
        font-size:1rem;
    }

    .rulesBoxContainer {
        border-radius:0px;
        height:fit-content;
    }

    .rulesImgContainer > img {
        width:75%;
    }

    .rulesJoinButton {
        font-size:1.25rem;
        margin-top:2rem;
    }
}