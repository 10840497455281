.partnersContainer {
    background-color: #eeebf1;
    border-radius: 0 0 50% 50%/ 0 0 5% 5%;
    padding-bottom:60px;
    z-index:2;
    position: relative;
}

.partnerCard {
    
    border-radius: 5px;
    padding:10px;
    
    width: fit-content;
    transition: all .3s ease;

    background-color: rgba(238, 241, 244,1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 

}

.partnerCard:hover {
    transform:scale(1.1);
    background-color: rgba(238, 241, 244,0);
    box-shadow:0px 0px 0px 0px;
    
}

.partnerCardImg {
    max-width:100%;
    max-height:100%;
    object-fit: contain;
}

.partnersTitleContainer {
    font-size:1.5rem;
    text-transform:uppercase;
    color:var(--mov);
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.partnersTitleContainer > h3 {
    margin-bottom:0px;
}

.partnersTitleLine {
    height:3px;
    background-color: var(--mov);
    margin-bottom: 10px;
}

.partnersTitleSmallLine {
    width:8%;
}
.partnersTitleBigLine {
    width:9%;
}

.partnerCardsContainer {
    display:flex;
    gap:20px;
    justify-content: center;
    margin-bottom:10px;
    flex-wrap:wrap;
}

@media only screen and (max-width: 768px){
    .partnersTitleSmallLine {
        width:25%;
    }
    .partnersTitleBigLine {
        width:30%;
    }

    .partnersContainer {
        border-radius: 0 0 50% 50%/ 0 0 1% 1%;
    }
}
