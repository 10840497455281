.rocketContainer {
    background-image: url("../../icons/racheta.svg");
    background-size:contain;
    background-repeat:no-repeat;
    min-width: 452px;
    min-height:624px;
    position:relative;
    transition: all .3s ease;
}



.rocketContainer > button:nth-child(1) {
    position:absolute;
    left:51%;
    transform:translateX(-50%);
    top:41%;
    font-size:24px;
    background-color:#F9FBFC;
    border:none;
}

.rocketContainer > button:nth-child(2) {
    position:absolute;
    left:38%;
    transform:translateX(-50%);
    top:62%;
    font-size:20px;
    background-color:#F9FBFC;
    border:none;
    width:58px;
}

.rocketContainer > button:nth-child(3) {
    position:absolute;
    left:62%;
    transform:translateX(-50%);
    top:62%;
    font-size:20px;
    background-color:#F9FBFC;
    border:none;
    width:58px;
}


.rocketContainer > button:nth-child(4) {
    position:absolute;
    left:38%;
    transform:translateX(-50%);
    top:80%;
    font-size:20px;
    background-color:#F9FBFC;
    border:none;
    width:58px;
}

.rocketContainer > button:nth-child(5) {
    position:absolute;
    left:62%;
    transform:translateX(-50%);
    top:80%;
    font-size:20px;
    background-color:#F9FBFC;
    border:none;
    width:58px;
}
.rocketContainer > button:hover {
    transform:translateX(-50%) scale(1.1);
}

.programTableVertLine {
    display:inline-block;
    width:1px;
    height:100%;
    margin:0rem .25rem;
    background-color: var(--roz);
    transform:translateY(15px);
}

.programHour {
    width:50px;
    display:inline-block;
    text-align: center;
}

.programLineContainer {
    height:fit-content;
    font-size:1.25rem;
}

.programTable {
    height:fit-content;
}

.programHoursContainer {
    color:var(--roz);
}

.programEventName {
    color:var(--alb);
}

.programContainer {
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    transition: all .3s ease;
}

.programTableContainer {
    width:50%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.programDay {
    color:var(--alb);
}

@media only screen and (max-width: 768px) {

    .programEventName {
        color:var(--mov)
    }
    .rocketContainer {
        transform:scale(.75);
    }
    .modalContainer {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .closeModal {
        background-color:var(--mov);
        color:var(--alb);
        margin-top:2rem;
        font-size:1.25rem;
        border:none;
        padding: 5px 10px;
        border-radius:15px;
    }

    .programContainer {
        margin-bottom:-100px;
    }
}