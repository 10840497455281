.titleCard {
  margin: 0 auto;
  width: 50%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  margin-top: 100px;
  margin-bottom: 100px;
}

.titleCard > h1 {
  text-align: center;
  font-size: 72px;
  background: -webkit-linear-gradient(
    148deg,
    var(--mov) 0%,
    var(--mov) 20%,
    var(--roz) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleCard > img {
  margin: 0 auto;
  width: 33%;
  animation: pulse 5s ease;
  animation-iteration-count: infinite;
}

@media only screen and (max-width: 768px) { 
  .titleCard > h1 {
    width:80%;
    font-size:2rem;
  }

  .titleCard {
    width:100%;
    min-width:50%;
    height:50vh;
  }
}