.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: var(--mov);
}

.footerIcons {
  display: flex;
  justify-content: space-between;
  width: 15%;
}

.footerIcons > img {
  width: 30px;
  height: 30px;
}

.footerLocation {
  display: flex;
  align-items: center;
}

.footerLocation > img {
  width: 30px;
  height: 30px;
}

.footerPhone {
  display: flex;
  align-items: center;
}

.footerPhone > img {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width:768px) {
  .footerContainer {
    font-size:.75rem;
    margin-bottom:1rem;
    gap:10px;
  }

  .footerContainer img {
    width:20px;
    height:20px;
  }

  .footerIcons img {
    width:30px;
    height:30px;
  }

  .footerIcons {
    width:66%;
  }
}