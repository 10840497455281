.card_title {
    position: absolute;
    bottom: 60px;
    left: 14px;
    font-size: 36px;
    color: var(--alb);
    font-family: 'Lato';
    font-weight: 700;
    width:100%;
}

.card_subtitle {
    position: absolute;
    bottom: 34px;
    left: 14px;
    font-size: 24px;
    color: var(--alb);
    font-family: 'Lato';
    font-weight: 600;
    width:100%;
}
.cardImg {
    position: relative;
    min-height:280px;
}


@media only screen and (max-width: 768px) {
    .card_title {
      font-size: 20px;
      bottom: 40px;
      left: 7px;
    }
    .card_subtitle {
      font-size: 12px;
      bottom: 20px;
      left: 7px;
    }
  }
  