.swiper-wrapper {
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.swiper-coverflow {
  overflow: visible !important;
}

.container {
  max-width: 100dvw;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  display: flex;
  margin-top: 40px;
}

.swiper-slide {
  transition: transform 0.3s ease;
  opacity: 0.3;
  transition: all 0.3s ease;
  overflow-y: visible;
}

.swiper-slide,
.swiper-slide img {
  width: 20dvw;
  height: 60dvh;
  object-fit: cover;
  border-radius: 0 100px 0 0;
  transition: all 0.3s ease;
}

.swiper-slide-active {
  opacity: 1;
  overflow-y: visible;
}

.swiper-slide-active > div {
  overflow-y: visible;
}

.swiper-slide-active > div > img {
  transform: scale(1.3);
}
.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  background-color: red;
  height: 50px;
  width: 50px;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.horizontalImageScrollTitle {
  padding-left:60px;
  font-size:3rem;
  margin-top:10px;
}

@media only screen and (max-width: 768px) {
  .swiper-slide,
  .swiper-slide img {
    width: 50dvw;
    height: auto;
    border-radius: 0 50px 0 0;
  }

  .swiper-pagination {
    opacity: 0.3;
  }
  .horizontalImageScrollTitle {
    padding-left:0px;
    font-size:3rem;
    margin-top:10px;
    text-align: center;
  }
}
